<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M9.272.172L8.683.66l-.079.076-8.41 9.32a.75.75 0 0 0-.194.502v6.907C0 18.588.912 19.5 2.036 19.5h3.857a2.036 2.036 0 0 0 2.036-2.036v-5.143c0-.295.24-.535.535-.535h2.572c.295 0 .535.24.535.535v5.143c0 1.124.912 2.036 2.036 2.036h3.857a2.036 2.036 0 0 0 2.036-2.036v-6.907a.75.75 0 0 0-.193-.502L10.896.735l-.079-.076-.589-.487a.75.75 0 0 0-.956 0zm.547 1.608L18 10.846v6.618c0 .296-.24.536-.536.536h-3.857a.536.536 0 0 1-.536-.536v-5.143a2.036 2.036 0 0 0-2.035-2.035H8.464A2.036 2.036 0 0 0 6.43 12.32v5.143c0 .296-.24.536-.536.536H2.036a.536.536 0 0 1-.536-.536v-6.618L9.681 1.78l.069-.057.069.057z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
